import React from 'react';
import {
  StyledArrow,
  StyledIconWrapper,
  StyledText,
  StyledWrapper,
} from './StyledThemeBox';
import IconsList from '../IconsList';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

function ThemeBox({ isThemeMenuOpen, toggleThemeMenu }) {
  const handleOutSideClick = () => {
    if (isThemeMenuOpen) {
      toggleThemeMenu(false);
    }
  };

  const ref = useOnClickOutside(handleOutSideClick);

  const handleToggle = () => {
    if (isThemeMenuOpen) {
      toggleThemeMenu(false);
    } else {
      toggleThemeMenu(true);
    }
  };

  return (
    <>
      <StyledWrapper isOpen={isThemeMenuOpen}>
        <StyledIconWrapper>
          <IconsList isFooterToggled={true} />
        </StyledIconWrapper>
        <StyledText>v2.0.6</StyledText>
      </StyledWrapper>
      <button style={{ all: 'unset' }} ref={ref} onClick={handleToggle}>
        <StyledArrow isOpen={isThemeMenuOpen} />
      </button>
    </>
  );
}

export default ThemeBox;
