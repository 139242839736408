import React, { memo, useContext } from 'react';

import Clock from 'react-live-clock';

import { MainImage } from 'gatsby-plugin-image';
import { handleIconClick } from '../../../../helpers/global';
import context from '../../../../store/context';
import Audio from './Audio';
import { ClockButton, SvgWrapper, Text, Wrapper } from './StyledToolBar';

function ToolBar({ toggleAntivirusModal, isFooterToggled }) {
  const { actions } = useContext(context);
  const handleOpenAntivirusModal = () => {
    toggleAntivirusModal(true);
  };

  const handleClockClick = () => {
    handleIconClick({ machineName: 'clock', href: '', ...actions });
  };

  return (
    <Wrapper>
      {isFooterToggled && (
        <SvgWrapper onClick={handleOpenAntivirusModal}>
          <MainImage src="/icons/antivirus.svg" width={22} alt="antivirus" />
        </SvgWrapper>
      )}
      <SvgWrapper>
        <Audio />
      </SvgWrapper>
      <ClockButton
        type="button"
        aria-label="Hidden Images"
        onClick={handleClockClick}
      >
        <Text>
          <Clock format="LT" ticking />
        </Text>
      </ClockButton>
    </Wrapper>
  );
}

export default memo(ToolBar);
