import styled from 'styled-components';

const IconWrapper = styled.div`
  &:hover {
    cursor: pointer;
    filter: drop-shadow(6px 6px 8px black);
  }
  &,
  & .icon,
  & .icon img {
    height: 24px !important;
    width: 24px !important;
    margin-right: 12px;

    ${(props) => props.theme.breakpoints.up('lg')} {
      width: 24px !important;
      height: 24px !important;
    }
    ${(props) => props.theme.breakpoints.up('xl')} {
      width: 32px !important;
      height: 32px !important;
      margin-right: 16px;
    }
    ${(props) => props.theme.breakpoints.up('xxl')} {
      width: 44px !important;
      height: 44px !important;
      margin-right: 24px;
    }
  }
`;

export default IconWrapper;
