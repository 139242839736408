import React, { useContext, useLayoutEffect, useState } from 'react';

import Cookies from 'universal-cookie';

import Background from './Background';
import Footer from './Footer';
import SleepingMode from './SleepingMode';
import StyledWrapper from './StyledLayout';
import Context from '../../store/context';

export default function Layout({ children }) {
  const { state, actions } = useContext(Context);
  const [isLoadingShown, setIsLoadingShown] = useState(null);

  useLayoutEffect(() => {
    const cookies = new Cookies();
    const showLoading = cookies.get('showLoading', {
      doNotParse: false,
    });
    if (typeof showLoading === 'undefined' || !showLoading) {
      setIsLoadingShown(showLoading);
    } else {
      setIsLoadingShown(showLoading);
    }
  });

  const {
    activeBg,
    isMenuOpen,
    preLoading,
    isSleepingModeOn,
    modals,
    isFooterToggled,
    isThemeMenuOpen,
  } = state;
  const { setSleepingMode, ...restActions } = actions;
  const handleClick = () => {
    if (isSleepingModeOn) {
      setSleepingMode(false);
    }
  };

  if (isLoadingShown === null) {
    return null;
  }

  return (
    <StyledWrapper onClick={handleClick}>
      <main>
        {children}
        <Background activeBg={activeBg} />
      </main>
      <Footer
        isThemeMenuOpen={isThemeMenuOpen}
        isMenuOpen={isMenuOpen}
        modals={modals}
        {...restActions}
        isFooterToggled={isFooterToggled}
        preLoading={preLoading}
      />
      <SleepingMode isSleepingModeOn={isSleepingModeOn} {...actions} />
    </StyledWrapper>
  );
}
