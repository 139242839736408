export const SET_PRELOADING = 'SET_PRELOADING';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_DESKTOP_BACKGROUND = 'SET_DESKTOP_BACKGROUND';
export const TOGGLE_ABOUT_MODAL = 'TOGGLE_ABOUT_MODAL';
export const TOGGLE_CONTACT_US_MODAL = 'TOGGLE_CONTACT_US_MODAL';
export const SET_SLEEPING_MODE = 'SET_SLEEPING_MODE';
export const TOGGLE_TEAM_MODAL = 'TOGGLE_TEAM_MODAL';
export const SET_GLOBAL_ZINDEX = 'SET_GLOBAL_ZINDEX';
export const TOGGLE_OUR_SERVICES_MODAL = 'TOGGLE_OUR_SERVICES_MODAL';
export const TOGGLE_TRASH_MODAL = 'TOGGLE_TRASH_MODAL';
export const TOGGLE_LASERWAR_MODAL = 'TOGGLE_LASERWAR_MODAL';
export const TOGGLE_CLOCK_MODAL = 'TOGGLE_CLOCK_MODAL';
export const TOGGLE_MINESWEEPER_MODAL = 'TOGGLE_MINESWEEPER_MODAL';
export const TOGGLE_PAINT_MODAL = 'TOGGLE_PAINT_MODAL';
export const TOGGLE_ANTIVIRUS_MODAL = 'TOGGLE_ANTIVIRUS_MODAL';
export const SET_CLOSE_ALL_MODALS = 'SET_CLOSE_ALL_MODALS';
export const TOGGLE_SEARCH_MODAL = 'TOGGLE_SEARCH_MODAL';
export const TOGGLE_FOOTER = 'TOGGLE_FOOTER';
export const TOGGLE_PROJECTS_MODAL = 'TOGGLE_PROJECTS_MODAL';
export const TOGGLE_WEB3COMPASS_MODAL = 'TOGGLE_WEB3COMPASS_MODAL';
export const TOGGLE_THRUPENNY_MODAL = 'TOGGLE_THRUPENNY_MODAL';
export const TOGGLE_COYOTE_MODAL = 'TOGGLE_COYOTE_MODAL';
export const TOGGLE_CLOVERCOIN_MODAL = 'TOGGLE_CLOVERCOIN_MODAL';
export const TOGGLE_GOGOFINANCE_MODAL = 'TOGGLE_GOGOFINANCE_MODAL';
export const TOGGLE_MOMENTO_MODAL = 'TOGGLE_MOMENTO_MODAL';
export const TOGGLE_JUSTYOURS_MODAL = 'TOGGLE_JUSTYOURS_MODAL';
export const TOGGLE_YOP_MODAL = 'TOGGLE_YOP_MODAL';
export const TOGGLE_MORTAL_METACITIES = 'TOGGLE_MORTAL_METACITIES';
export const TOGGLE_KONSTA_MODAL = 'TOGGLE_KONSTA_MODAL';
export const TOGGLE_PARTNERS_MODAL = 'TOGGLE_PARTNERS_MODAL';
export const TOGGLE_NFTLOOTS_MODAL = 'TOGGLE_NFTLOOTS_MODAL';
export const TOGGLE_CHUBCAY_MODAL = 'TOGGLE_CHUBCAY_MODAL';
export const TOGGLE_AUTHENTICO_MODAL = 'TOGGLE_AUTHENTICO_MODAL';
export const TOGGLE_SYNCDAO_MODAL = 'TOGGLE_SYNCDAO_MODAL';
export const TOGGLE_OURGOALS_MODAL = 'TOGGLE_OURGOALS_MODAL';
export const TOGGLE_DOME_MODAL = 'TOGGLE_DOME_MODAL';
export const TOGGLE_SILKSWAP_MODAL = 'TOGGLE_SILKSWAP_MODAL';
export const TOGGLE_POKERDAO_MODAL = 'TOGGLE_POKERDAO_MODAL';
export const TOGGLE_CRENFT_MODAL = 'TOGGLE_CRENFT_MODAL';
export const TOGGLE_CRESTAKING_MODAL = 'TOGGLE_CRESTAKING_MODAL';
export const TOGGLE_TORTUGABET_MODAL = 'TOGGLE_TORTUGABET_MODAL';
export const TOGGLE_RABBIT_MODAL = 'TOGGLE_RABBIT_MODAL';
export const TOGGLE_THEME_MENU = 'TOGGLE_THEME_MENU';
export const TOGGLE_FEDROK_MODAL = 'TOGGLE_FEDROK_MODAL';
export const TOGGLE_FEDROK_EXPLORER_MODAL = 'TOGGLE_FEDROK_EXPLORER_MODAL';
export const TOGGLE_FEDROK_BLOCKCHAIN_MODAL = 'TOGGLE_FEDROK_BLOCKCHAIN_MODAL';
export const TOGGLE_FIRO_INTO_ENKRYPT_MODAL = 'TOGGLE_FIRO_INTO_ENKRYPT_MODAL';
export const TOGGLE_NODEFUN_MODAL = 'TOGGLE_NODEFUN_MODAL';
export const TOGGLE_DID_WEB3_MODAL = 'TOGGLE_DID_WEB3_MODAL';
export const TOGGLE_WEB3_MODAL = 'TOGGLE_WEB3_MODAL';
export const TOGGLE_PREDICTOR_MODAL = 'TOGGLE_PREDICTOR_MODAL';

export const SET_MAXIMIZE_CONTACTS_MODAL = 'SET_MAXIMIZE_CONTACTS_MODAL';
export const SET_MAXIMIZE_ABOUTUS_MODAL = 'SET_MAXIMIZE_ABOUTUS_MODAL';
export const SET_MAXIMIZE_MINESWEEPER_MODAL = 'SET_MAXIMIZE_MINESWEEPER_MODAL';
export const SET_MAXIMIZE_PARTNERS_MODAL = 'SET_MAXIMIZE_PARTNERS_MODAL';
export const SET_MAXIMIZE_OURSERVICES_MODAL = 'SET_MAXIMIZE_OURSERVICES_MODAL';
export const SET_MAXIMIZE_PAINT_MODAL = 'SET_MAXIMIZE_PAINT_MODAL';
export const SET_MAXIMIZE_TEAMS_MODAL = 'SET_MAXIMIZE_TEAMS_MODAL';
export const SET_MAXIMIZE_TRASH_MODAL = 'SET_MAXIMIZE_TRASH_MODAL';
export const SET_MAXIMIZE_CLOCK_MODAL = 'SET_MAXIMIZE_CLOCK_MODAL';
export const SET_MAXIMIZE_PROJECTS_MODAL = 'SET_MAXIMIZE_PROJECTS_MODAL';
export const SET_MAXIMIZE_FEDROK_MODAL = 'SET_MAXIMIZE_FEDROK_MODAL';
export const SET_MAXIMIZE_FEDROK_EXPLORER_MODAL =
  'SET_MAXIMIZE_FEDROK_EXPLORER_MODAL';
export const SET_MAXIMIZE_FEDROK_BLOCKCHAIN_MODAL =
  'SET_MAXIMIZE_FEDROK_BLOCKCHAIN_MODAL';
export const SET_MAXIMIZE_NODEFUN_MODAL = 'SET_MAXIMIZE_NODEFUN_MODAL';
export const SET_MAXIMIZE_DID_WEB3_MODAL = 'SET_MAXIMIZE_DID_WEB3_MODAL';
export const SET_MAXIMIZE_WEB3_MODAL = 'SET_MAXIMIZE_WEB3_MODAL';
export const SET_MAXIMIZE_PREDICTOR_MODAL = 'SET_MAXIMIZE_PREDICTOR_MODAL';
export const SET_MAXIMIZE_FIRO_INTO_ENKRYPT_MODAL =
  'SET_MAXIMIZE_FIRO_INTO_ENKRYPT_MODAL';
export const SET_MAXIMIZE_COMPASS_PROJECT_MODAL =
  'SET_MAXIMIZE_COMPASS_PROJECT_MODAL';
export const SET_MAXIMIZE_YOP_PROJECT_MODAL = 'SET_MAXIMIZE_YOP_PROJECT_MODAL';
export const SET_MAXIMIZE_THRUPENNY_PROJECT_MODAL =
  'SET_MAXIMIZE_THRUPENNY_PROJECT_MODAL';
export const SET_MAXIMIZE_MORTAL_PROJECT_MODAL =
  'SET_MAXIMIZE_MORTAL_PROJECT_MODAL';
export const SET_MAXIMIZE_MOMENTO_PROJECT_MODAL =
  'SET_MAXIMIZE_MOMENTO_PROJECT_MODAL';
export const SET_MAXIMIZE_KONSTA_PROJECT_MODAL =
  'SET_MAXIMIZE_KONSTA_PROJECT_MODAL';
export const SET_MAXIMIZE_JUSTYOURS_PROJECT_MODAL =
  'SET_MAXIMIZE_JUSTYOURS_PROJECT_MODAL';
export const SET_MAXIMIZE_GOGO_PROJECT_MODAL =
  'SET_MAXIMIZE_GOGO_PROJECT_MODAL';
export const SET_MAXIMIZE_COYOTE_PROJECT_MODAL =
  'SET_MAXIMIZE_COYOTE_PROJECT_MODAL';
export const SET_MAXIMIZE_CLOVERCOIN_PROJECT_MODAL =
  'SET_MAXIMIZE_CLOVERCOIN_PROJECT_MODAL';
export const SET_MAXIMIZE_POKERDAO_MODAL = 'SET_MAXIMIZE_POKERDAO_MODAL';
export const SET_UNMAXIMIZE_ALL_MODALS = 'SET_UNMAXIMIZE_ALL_MODALS';
export const SET_MAXIMIZE_NFTLOOTS_MODAL = 'SET_MAXIMIZE_NFTLOOTS_MODAL';
export const SET_MAXIMIZE_CHUBCAY_MODAL = 'SET_MAXIMIZE_CHUBCAY_MODAL';
export const SET_MAXIMIZE_AUTHENTICO_MODAL = 'SET_MAXIMIZE_AUTHENTICO_MODAL';
export const SET_MAXIMIZE_SYNCDAO_MODAL = 'SET_MAXIMIZE_SYNCDAO_MODAL';
export const SET_MAXIMIZE_OURGOALS_MODAL = 'SET_MAXIMIZE_OURGOALS_MODAL';
export const SET_MAXIMIZE_DOME_MODAL = 'SET_MAXIMIZE_DOME_MODAL';
export const SET_MAXIMIZE_SILKSWAP_MODAL = 'SET_MAXIMIZE_SILKSWAP_MODAL';
export const SET_MAXIMIZE_CRENFT_MODAL = 'SET_MAXIMIZE_CRENFT_MODAL';
export const SET_MAXIMIZE_CRESTAKING_MODAL = 'SET_MAXIMIZE_CRESTAKING_MODAL';
export const SET_MAXIMIZE_TORTUGABET_MODAL = 'SET_MAXIMIZE_TORTUGABET_MODAL';
export const SET_MAXIMIZE_LASERWAR_MODAL = 'SET_MAXIMIZE_LASERWAR_MODAL';
export const SET_MAXIMIZE_RABBIT_MODAL = 'SET_MAXIMIZE_RABBIT_MODAL';
