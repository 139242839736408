import React, { memo, useEffect, useState } from 'react';

import { useLocation } from '@gatsbyjs/reach-router';
import { useTheme } from 'styled-components';
import Cookies from 'universal-cookie';

import { MainImage } from 'gatsby-plugin-image';
import useMediaQuery from '../../../hooks/useMediaQuery';
import StartButton from './StartButton/StartButton';
import StartMenu from './StartMenu/StartMenu';
import {
  FlexGroup,
  ImageWrapper,
  ShadowEffect,
  StyledFooter,
} from './StyledFooter';
import ThemeBox from './ThemeBox/ThemeBox';
import ToolBar from './ToolBar';

const cookie = new Cookies();

function Footer({
  isMenuOpen,
  toggleMenu,
  toggleThemeMenu,
  modals = {},
  preLoading,
  isFooterToggled,
  isThemeMenuOpen,
  ...actions
}) {
  const [isDesktopIconVisible, setDesktopIconVisible] = useState(false);
  const [hiddenFooter, setHiddenFooter] = useState(false);
  const [showFooter, setShowFooter] = useState(null);
  const theme = useTheme();
  const location = useLocation();
  const isSmUp = useMediaQuery(theme.breakpoints.sm);
  const { setAllModalsClose, toggleFooter, setUnMaximizeAllModals } = actions;
  let isPreloaded;

  useEffect(() => {
    const isModalOpen = Object.keys(modals).some((item) => modals[item]);
    setDesktopIconVisible(isModalOpen);

    if (location.pathname !== '/' && location.pathname.includes('/')) {
      toggleFooter(false);
    } else {
      toggleFooter(true);
    }

    if (location.pathname === '/' || location.pathname.includes('/blog')) {
      setHiddenFooter(true);
    }

    isPreloaded = cookie.get('showLoading', { doNotParse: false });
    setShowFooter(!isPreloaded ? null : isPreloaded);
  }, [modals]);

  const handleCloseAllModals = () => {
    setAllModalsClose();
    setUnMaximizeAllModals();
  };

  if (!hiddenFooter) {
    return null;
  }

  return (
    <StyledFooter
      isMenuOpen={isMenuOpen}
      isThemeMenuOpen={isThemeMenuOpen}
      showFooter={showFooter}
      preLoading={preLoading}
    >
      <FlexGroup position="relative" alignItems="center">
        {isMenuOpen && <StartMenu {...actions} />}
        {isFooterToggled && (
          <StartButton isOpen={isMenuOpen} toggleMenu={toggleMenu} />
        )}
        {isSmUp && isFooterToggled && <ShadowEffect />}
        {isDesktopIconVisible && isFooterToggled && (
          <ImageWrapper onClick={handleCloseAllModals}>
            <MainImage src="/icons/desktop.svg" width={30} alt="desktop" />
          </ImageWrapper>
        )}
      </FlexGroup>
      <FlexGroup alignItems="center" position="relative">
        {isFooterToggled && (
          <ThemeBox
            isThemeMenuOpen={isThemeMenuOpen}
            toggleThemeMenu={toggleThemeMenu}
          />
        )}
        <ToolBar {...actions} isFooterToggled={isFooterToggled} />
      </FlexGroup>
    </StyledFooter>
  );
}

export default memo(Footer);
