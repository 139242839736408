import styled from 'styled-components';

const arrowUp = '/images/themeBox/themeArrowUp.png';
const arrowDown = '/images/themeBox/themeArrowDown.png';

export const StyledArrow = styled.div`
  width: 28px;
  height: 28px;
  background-image: url(${(props) => (props.isOpen ? arrowDown : arrowUp)});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 22px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 32px;
    height: 32px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 32px;
    height: 32px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 36px;
    height: 36px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 51px;
    height: 51px;
  }
`;

export const StyledWrapper = styled.div`
  position: absolute;
  background-color: rgb(192, 192, 192);
  box-shadow: rgb(223, 223, 223) 4px 4px inset;
  padding: 12px;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.up('default')} {
    bottom: 60px;
    right: 90px;
    width: 174px;
    height: 86px;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    bottom: 60px;
    right: 90px;
    width: 187px;
    height: 95px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    bottom: 70px;
    right: 40px;
    width: 318px;
    height: 155px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    bottom: 65px;
    right: 40px;
    width: 318px;
    height: 155px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    bottom: 90px;
    right: 81px;
    width: 318px;
    height: 179px;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledText = styled.p`
  color: #606060;
  font-size: 14px;
  font-weight: 400;
  margin-left: 32px;
  padding-left: 12px;
  display: block;
  display: flex;
  justify-content: flex-end;
`;
