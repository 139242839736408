import React, { useRef, useState } from 'react';

import { MainImage } from 'gatsby-plugin-image';

function Audio() {
  const [isPlaying, setPlaying] = useState(false);
  const audio = useRef();
  const handlePlaySound = () => {
    setPlaying(true);
    audio.current.play();
  };

  const handleMuteSound = () => {
    audio.current.pause();
    setPlaying(false);
  };

  return (
    <>
      {isPlaying ? (
        <MainImage
          src="/icons/sound.svg"
          alt="sound"
          width={22}
          onClick={handleMuteSound}
        />
      ) : (
        <MainImage
          src="/icons/soundMute.svg"
          alt="sound-mute"
          onClick={handlePlaySound}
          width={22}
        />
      )}
      <audio ref={audio} loop preload="auto">
        <source src="/sounds/tetris.mp3" type="audio/mpeg" />
        <track kind="captions" />
      </audio>
    </>
  );
}

export default Audio;
