import React, { memo, useEffect, useState } from 'react';

import { useLocation } from '@gatsbyjs/reach-router';

import { MainImage } from 'gatsby-plugin-image';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { Button, IconWrapper, Text } from './StyledStartButton';

function StartButton({ isOpen, toggleMenu }) {
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    toggleMenu(!isOpen);
  };

  const handleOutSideClick = () => {
    if (isOpen) {
      toggleMenu(false);
    }
  };

  const ref = useOnClickOutside(handleOutSideClick);

  useEffect(() => {
    if (location.pathname.length > 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  return (
    <Button
      onClick={handleClick}
      ref={ref}
      disabled={isDisabled}
      isOpen={isOpen}
    >
      <IconWrapper>
        <MainImage src="/icons/windows.svg" width={23} alt="windows" />
      </IconWrapper>
      <Text>Start</Text>
    </Button>
  );
}

export default memo(StartButton);
